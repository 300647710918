// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakti-js": () => import("./../../../src/pages/kontakti.js" /* webpackChunkName: "component---src-pages-kontakti-js" */),
  "component---src-pages-lichni-danni-js": () => import("./../../../src/pages/lichni-danni.js" /* webpackChunkName: "component---src-pages-lichni-danni-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-obshti-usloviq-js": () => import("./../../../src/pages/obshti-usloviq.js" /* webpackChunkName: "component---src-pages-obshti-usloviq-js" */),
  "component---src-pages-za-nas-js": () => import("./../../../src/pages/za-nas.js" /* webpackChunkName: "component---src-pages-za-nas-js" */)
}

